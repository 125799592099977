import BaseGridExtension from 'o365.controls.Grid.BaseExtension.ts';

declare module 'o365.controls.Grid.BaseGrid.ts' {
    interface BaseGridControl {
        columnMove: BaseColumnMove;
    }
}

/** 
 * Extension class for adding column moving with drag events.
 * Should be created after grid has bee nmounted
 */
export default class BaseColumnMove extends BaseGridExtension {
    static __extension = 'columnMove';

    /** Grid root container */
    container: HTMLElement;
    // dataColumns: DataColumns;
    /** Previous clientX of the moved element */
    prevX: number;
    movedColumn: IColumnMoveNode;
    overColumnField: string;
    orderedColumns: IColumnMoveNode[] = [];
    moveToField: string;
    movedElement: HTMLElement;
    columnCache: Record<string, NodeListOf<HTMLElement>> = {};
    prevIndex: number = 0;
    prevDirection: any;
    prevMove: any;

    /** 
     * The movable guards tuple [x,y]
     * Will block moves who pass check: x >= targetIndex || columnsLength-y <= targetIndex
     * Null values inside the tuple will remove their respactive guard
     */
    movableGuards: [number, number]

    protected _dragImage: HTMLElement;
    protected _dragImageLabel: HTMLElement;


    initializeExtension() {
        const gridControl = this._getGridControl();
        if (gridControl.container == null) { throw new Error('Failed to create ColumnMove, grid container is null'); }

        this.container = gridControl.container;
        this._initDragImage();

        const guardStart = gridControl.props.hideMultiselectColumn
            ? (gridControl.props.hideSystemColumn ? null : 1)
            : (gridControl.props.hideSystemColumn ? 1 : 2)
        const guardEnd = gridControl.props.hideActionColumn
            ? null
            : 1;
        this.movableGuards = [guardStart, guardEnd];

        this.container.addEventListener('dragstart', (e: DragEvent) => {
            const target = <HTMLElement>e.target;
            if (!target || !target.getAttribute || !target.getAttribute('o365-field')) { return; }
            if (!target.classList.contains('o365-header-cell')) { return; }
            this.movedElement = target;

            this._cacheColumns();
            e.dataTransfer.clearData();
            e.dataTransfer.setData('text/plain', target.getAttribute('o365-field'));
            this.prevX = e.clientX;

            this.container.classList.add('o365-columns-moving');

            this.movedColumn = this.orderedColumns.find(x => x.field === target.getAttribute('o365-field'));
            this.movedColumn.initialOrder = this.movedColumn.order;

            this._dragImageLabel.textContent = this.movedColumn.title;
            this._dragImage.style.width = this.movedColumn.width + 'px';
            document.body.appendChild(this._dragImage);
            e.dataTransfer.setDragImage(this._dragImage, 0, 0);

            this._setClassForCachedColumns(this.movedColumn.field, 'column-moving');
            window.addEventListener('dragend', this._onDragEnd);
        }, false);

        this.container.addEventListener('dragend', (e) => {
            e.dataTransfer.dropEffect = 'copy';
        });

        this.container.addEventListener('dragover', (e) => {
            e.preventDefault();

            const target = <HTMLElement>e.target;
            const closestColEl = <HTMLElement>target.closest('[o365-field][data-o365-colindex]');

            if (!closestColEl) { return; }
            else if (this.prevX === e.clientX) { return; }

            const targetIndex = parseInt(closestColEl.dataset.colindex);
            const field = closestColEl.getAttribute('o365-field');
            if (!field) { return; }

            let moveDirection = null;
            if (e.clientX > this.prevX) {
                moveDirection = 'right';
            } else {
                moveDirection = 'left';
            }

            const directionChanged = moveDirection !== this.prevDirection;
            if (!directionChanged && targetIndex === parseInt(this.movedElement.dataset.colindex)) { return; }

            let startGuardCheck = false;
            if (this.movableGuards[0] != null) {
                startGuardCheck = targetIndex <= this.movableGuards[0];
            }
            let endGuardCheck = false;
            if (this.movableGuards[1] != null) {
                endGuardCheck = targetIndex >= this.orderedColumns.length - this.movableGuards[1];
            }
            if (startGuardCheck || endGuardCheck) {
                e.dataTransfer.dropEffect = 'none';
                return;
            }

            const column = this.orderedColumns.find(x => x.field === field);
            if (!column) { return; }

            if (this.movedColumn.pinned !== column.pinned) {
                e.dataTransfer.dropEffect = 'none';
                return;
            }

            if (this.overColumnField === field && this.prevDirection === moveDirection) { return; }
            this.overColumnField = field;

            this._moveColumn();
            this.prevDirection = moveDirection;

            this.prevX = e.clientX;
        }, false);

        this.container.addEventListener('drop', (_e) => {
            if (!this.movedColumn) { return; }
            window.removeEventListener('dragend', this._onDragEnd);


            this.container.classList.remove('o365-columns-moving');

            this._setClassForCachedColumns(this.movedColumn.field, "column-moving", true);

            this.orderedColumns.forEach((col, index) => {
                const isMovedCol = this.movedColumn.field === col.field;
                if (isMovedCol) {
                    // Legacy layouts
                    // this.dataColumns.getColumn(col.field).trackChange('order', index);
                }
                this._setColumnOrder(col.field, index);
            })

            this.movedColumn = null;
            this.orderedColumns = [];
            this.overColumnField = null;
            this._dragImage.remove();
            this.moveToField = null;
            this.columnCache = {};
            this.prevMove = null;
            this.prevDirection = null;
        }, false);
    }

    /** Prepare drag image element */
    protected _initDragImage() {
        this._dragImage = document.createElement('div');
        this._dragImage.id = "gridColumnDragImage";
        this._dragImage.classList.add('o365-grid-drag-image', 'rounded-2');
        const labelContainer = document.createElement('div');
        labelContainer.classList.add('drag-image-label-container', 'text-truncate')
        this._dragImageLabel = document.createElement('span');
        this._dragImageLabel.classList.add('text-truncate');

        this._dragImage.append(labelContainer);
        labelContainer.append(this._dragImageLabel);
    }

    /** Cahce current columns for moving */
    protected _cacheColumns() {
        this.columnCache = {};
        this.orderedColumns = [];

        const gridControl = this._getGridControl();
        gridControl.columns.columns.forEach(col => {
            if (!col.hide) {
                this.columnCache[col.colId] = this.container.querySelectorAll(`[o365-field="${col.colId}"][data-o365-colindex]`);
                this.orderedColumns.push({
                    field: col.colId,
                    order: col.order,
                    width: col.width,
                    pinned: col.pinned ? col.pinned : null,
                    title: col.headerName ?? col.field,

                    // parentGroupId: col.parentGroupId,
                    element: this.container.querySelector(`.o365-header-cell.o365-header-cell-container[o365-field="${col.colId}"][data-o365-colindex]`),
                    get left() { return parseInt(this.element.style.left) }
                })
            } else {
                this.orderedColumns.push({
                    field: col.colId,
                    order: col.order,
                    pinned: col.pinned ? col.pinned : null,
                    title: col.headerName ?? col.field,
                    // parentGroupId: col.parentGroupId,
                    hide: true
                });
            }
        });
    }

    /** Set clases for cached column elements */
    protected _setClassForCachedColumns(columnName: string, className: string, remove?: boolean) {
        if (!remove) {
            this.columnCache[columnName].forEach(el => {
                el.classList.add(className);
            });
        } else {
            this.columnCache[columnName].forEach(el => {
                el.classList.remove(className);
            });
        }
    }

    /** onDragEnd handler */
    protected _onDragEnd = (_e: DragEvent) => {
        window.removeEventListener('dragend', this._onDragEnd);

        if (!this.movedColumn) { return; }


        this.container.classList.remove('o365-columns-moving');

        this._setClassForCachedColumns(this.movedColumn.field, 'column-moving', true);

        this.orderedColumns.forEach((col, index) => {
            const isMovedCol = this.movedColumn.field === col.field;
            if (isMovedCol) {
                // Legacy layouts
                // this.dataColumns.getColumn(col.field).trackChange('order', index);
            }
            this._setColumnOrder(col.field, index);
        })


        this.movedColumn = null;
        this.orderedColumns = [];
        this.overColumnField = null;
        this._dragImage.remove();
        this.moveToField = null;
        this.columnCache = {};
        this.prevMove = null;
    }

    /** Function for completing the column move  */
    protected _moveColumn() {
        const columnToIndex = this.orderedColumns.findIndex(x => x.field === this.overColumnField);
        const columnFromIndex = this.orderedColumns.findIndex(x => x.field === this.movedColumn.field);

        let sumLeft = 0;
        let sum = 0;
        let sumRight = 0;
        this.orderedColumns.splice(columnFromIndex, 1);
        this.movedColumn.order = columnToIndex;
        this.orderedColumns.splice(columnToIndex, 0, this.movedColumn);

        this.orderedColumns.forEach(col => {
            if (col.hide) { return; }
            if (col.pinned === 'left') {
                this._setLeftProp(col.field, sumLeft);
                sumLeft += col.width;
            } else if (col.pinned === 'right') {
                this._setLeftProp(col.field, sumRight);
                sumRight += col.width;
            } else {
                this._setLeftProp(col.field, sum);
                sum += col.width;
            }
        });
    }


    /** Updated cahced elements left prop */
    protected _setLeftProp(columnName: string, left: number) {
        if (this.columnCache[columnName].length === 0) { return; }
        if (this.columnCache[columnName][0].style.left === left.toString() + 'px') { return; }
        this.columnCache[columnName].forEach(el => {
            el.style.left = left + 'px';
        });
    }

    /** Set order on column object */
    protected _setColumnOrder(colId: string, order: number) {
        const gridControl = this._getGridControl();
        gridControl.columns.columns.find(x => x.colId === colId).order = order;
    }
}

interface IColumnMoveNode {
    field: string;
    order: number;
    width?: number;
    pinned?: 'left' | 'right';
    title?: string;
    element?: HTMLElement;
    left?: number;
    hide?: boolean;
    initialOrder?: number;
}